import React, { useContext, useEffect, useState } from 'react';

import { PbsKidsProperty } from '@/types/pbskids-graph';
import { queryGraphClient } from '@/utils/graphql';
import { ResolvedPageThemes } from '@/utils/theming-system';
import { StationLogoTheme } from '@/types/stations';

import { defaultTheme } from '@/utils/theming-system/defaultTheme';
import getPropertyMetadata from '@/queries/property/getPropertyMetadata.graphql';
import LanguageLocalizer from '@/utils/language-localization';
import AnimatedLogo from '@/components/base/AnimatedLogo';
import ClickAnimation from '@/components/base/ClickAnimationWrapper';
import LocalStationLogo from '@/components/global/LocalStationLogo';
import NavButton from '@/components/global/NavButton';
import PropertyLogo from '@/components/global/PropertyLogo';
import StationConfirmation from '@/components/global/StationConfirmation';
import StyledIcon from '@/components/base/StyledIcon';

import GamesIcon from '@/public/puma/images/icons/Games.svg';
import VideosIcon from '@/public/puma/images/icons/Videos.svg';

import { handleLinkClickWithDelay } from '@/utils/link-with-delay';

import styles from './SiteHeader.module.scss';

type Props = {
  isTopLevelPage?: boolean
  pageTitle?: string
  propertyId?: string | number
  resolvedPageThemes?: ResolvedPageThemes
};

export default function SiteHeader({ isTopLevelPage, pageTitle, propertyId, resolvedPageThemes }: Props) {
  const { __t } = new LanguageLocalizer( useContext, 'components/global/SiteHeader' );
  const [ propertyData, setPropertyData ] = useState<PbsKidsProperty|null>(null);
  const [ propertyDataLoading, setPropertyDataLoading ] = useState<boolean>(false);

  const logoTheme = [
    resolvedPageThemes?.primaryTheme?.stationLogoStyle,
    defaultTheme.stationLogoStyle,
    StationLogoTheme.Color,
  ].find((value) => !!value) as StationLogoTheme;

  useEffect(() => {
    if (!propertyId) return;

    setPropertyDataLoading(true);

    queryGraphClient(getPropertyMetadata, { propertyId: propertyId })
      .then((data) => {
        setPropertyData(data.pbsKidsProperty);
        setPropertyDataLoading(false);
      });

    return () => {
      setPropertyData(null);
      setPropertyDataLoading(false);
    };
  }, [ propertyId ]);

  return <>
    <header className={`${styles.outerWrapper} ${isTopLevelPage ? styles.isTopLevelPage : ''}`}>
      { pageTitle && <h1 className='sr-only'>{pageTitle}</h1> }

      <nav
        aria-labelledby='site-nav-title'
        className={styles.innerWrapper}
      >
        <h2
          id='site-nav-title'
          className='sr-only'>
          {__t('siteNav')}
        </h2>
        <div>
          <a
            href={'/'}
            className={`${styles.navIconButton} ${styles.pbsKidsLogo}`}
            onClick={handleLinkClickWithDelay}
          >
            <ClickAnimation>
              <AnimatedLogo
                title={__t('pbsKidsHome')}
              />
            </ClickAnimation>
          </a>

          <NavButton
            isExternalPage={true}
            link='/games'
            title = {__t('games')}
            classList={styles.navIconButton}
            iconName='games'
          >
            <StyledIcon
              icon={ GamesIcon }
            ></StyledIcon>
          </NavButton>

          <NavButton
            link='/videos'
            title={__t('videos')}
            classList={styles.navIconButton}
            iconName='videos'
          >
            <StyledIcon
              icon={ VideosIcon }
            ></StyledIcon>
          </NavButton>
        </div>

        <div>
          <LocalStationLogo
            logoTheme={logoTheme}
            wrapperClasslist={styles.stationLogoOuter}
            innerClasslist={styles.stationLogoInner}
            sizes='(max-width: 767px) 120px, 240px'
            appendElement={
              <StationConfirmation />
            }
          />
        </div>
      </nav>

      <PropertyLogo
        loading={propertyDataLoading}
        link={propertyData?.websiteUrl || ''}
        logo={propertyData?.logo?.[0]}
        title={propertyData?.title || ''}
      />
    </header>
  </>;
}
