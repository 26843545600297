import React from 'react';

const handleLinkClickWithDelay = (ev: React.MouseEvent) => {
  // Adds a delay before a link directs to its href.
  // Helpful for our nav buttons: Since some sections are not
  // technically on this site yet, a delay ensures that the click
  // animations complete before the page refreshes.
  ev.preventDefault();
  setTimeout(() => {
    const target = ev.target as HTMLElement;
    const anchor = target.closest('a');
    window.location.href = anchor?.href || window.location.href;
  }, 200);
};

export { handleLinkClickWithDelay };
