import React, { FocusEvent, MouseEvent, useRef } from 'react';
import { debounce } from 'ts-debounce';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';
import ClickAnimation from '@/components/base/ClickAnimationWrapper';
import { handleLinkClickWithDelay } from '@/utils/link-with-delay';
import styles from './NavButton.module.scss';

interface NavButtonWrapperProps {
  ariaLabelText?: string
  children?: ReactNode
  classList?: string
  iconName?: string
  isExternalPage?: boolean
  link: string
}

interface NavButtonProps extends NavButtonWrapperProps {
  title?: string
}

const NavButtonWrapper: React.FC<NavButtonWrapperProps> = ({
  ariaLabelText,
  classList,
  iconName,
  isExternalPage,
  link,
  children,
}) => {
  const isAnimating = useRef(false);
  const linkRef = useRef<HTMLAnchorElement|null>(null);

  const onAnimationEnd = () => {
    linkRef.current?.classList.remove('jellySquish');
    isAnimating.current = false;
  };

  const animateHover = () => {
    if (!linkRef.current || isAnimating.current) {
      return;
    }
    isAnimating.current = true;
    linkRef.current.addEventListener('animationend', onAnimationEnd);
    linkRef.current.classList.add('jellySquish');
  };

  const debouncedAnimate = debounce(
    // eslint-disable-next-line no-unused-vars
    (_ev: MouseEvent<HTMLElement>|FocusEvent<HTMLElement>) => animateHover(), 250, { isImmediate: true },
  );

  const linkProps = {
    'aria-label': ariaLabelText,
    'className': classList,
    'data-icon-name': iconName,
    'href': link,
    'onFocus': debouncedAnimate,
    'onMouseEnter': debouncedAnimate,
    'ref': linkRef,
  };

  return isExternalPage ? (
    <a
      { ...linkProps }
      ref={ linkRef }
      onClick={ handleLinkClickWithDelay }
    >
      { children }
    </a>
  ) : (
    <Link { ...linkProps }>
      { children }
    </Link>
  );
};

const NavButton: React.FC<NavButtonProps> = ({
  ariaLabelText,
  link,
  children,
  classList,
  iconName,
  isExternalPage = false,
  title,
}) => {
  const pathname = usePathname();
  const isCurrent = pathname.search(`^${link}`) !== -1;

  return (
    <NavButtonWrapper
      ariaLabelText={ ariaLabelText }
      classList={ `${styles.button} ${classList || ''}` }
      iconName={ iconName }
      isExternalPage={ isExternalPage }
      link={ link }
    >
      <ClickAnimation>
        <div className={ `${styles.buttonInner}` }>
          { children ? children : '' }
          { title ? <span className={ `${styles.title}` }>{ title }</span> : ''}
          { isCurrent ? <span className='sr-only'> (current section)</span> : '' }
        </div>
      </ClickAnimation>
    </NavButtonWrapper>
  );
};

export default NavButton;
