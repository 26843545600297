import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { ImageAsset } from '@/types/pbskids-graph';
import styles from './PropertyLogo.module.scss';

interface PropertyLogoProps {
  loading: boolean
  link?: string
  logo?: ImageAsset|null
  title?: string
}

const PropertyLogo: React.FC<PropertyLogoProps> = ({
  loading = false,
  link,
  logo,
  title = '',
}) => {
  const img = logo?.url && (
    <Image
      width={logo?.width ?? 304}
      height={logo?.height ?? 180}
      sizes='(max-width: 767px) 112px, 240px'
      src={logo?.url}
      alt={title}
    />
  );

  return <>
    {/* While the property data is loading, we want to reserve space for
    the logo so we avoid an ugly layout shift which happens if we do not. */}
    { (loading || !!logo?.url) && (

      <div className={styles.propertyLogoWrapper}>
        <div className={`${styles.propertyLogo} ${loading ? 'loader-bug' : ''}`}>
          {link ? (
            <Link href={link}>
              {img}
            </Link>
          ) : (
            img
          )}
        </div>
      </div>
    )}
  </>;
};

export default PropertyLogo;
