import { MouseEvent, useRef } from 'react';
import { debounce } from 'ts-debounce';
import PBSKidsLogo from '@/public/puma/images/logos/PBSKidsLogo.svg';
import styles from './AnimatedLogo.module.scss';

export default function AnimatedLogo({ title }: { title?: string }) {
  const wrapperRef = useRef<HTMLDivElement|null>(null);
  const innerRef = useRef<HTMLDivElement|null>(null);
  const isAnimating = useRef(false);

  const resetAnimations = () => {
    setTimeout(() => {
      // Let *some* of the animation finish so it's responsive
      // to mouseout but not so abrupt.
      wrapperRef.current?.classList.remove('popcorn');
      innerRef.current?.classList.remove('logo-jelly-squish');
      isAnimating.current = false;
    }, 225);
  };

  const animateLogo = () => {
    if (isAnimating.current) {
      return;
    }

    isAnimating.current = true;
    wrapperRef.current?.classList.add('popcorn');
    innerRef.current?.classList.add('logo-jelly-squish');
  };

  // eslint-disable-next-line no-unused-vars
  const debouncedAnimateLogo = debounce((_ev: MouseEvent<HTMLElement>) => animateLogo(), 500, { isImmediate: true });

  return (
    <div
      id='logo-wrap'
      ref={wrapperRef}
      className={styles.wrapper}
      onMouseEnter={debouncedAnimateLogo}
      onMouseLeave={resetAnimations}>
      <div ref={innerRef}>
        <PBSKidsLogo
          role='img'
          title={title}
        />
      </div>
    </div>
  );
}
