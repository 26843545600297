import { isAutoLocalizationConfirmed, setConfirmedAutoLocalizationCookie } from '@/managers/StationLocalization';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { localStorageWrapper as localStorage, sessionStorageWrapper as sessionStorage } from '@/utils/browser-state';
import LanguageLocalizer from '@/utils/language-localization';
import StationLocalizationContext from '@/contexts/StationLocalizationContext';
import StationSettings from '@/components/modules/StationSettings';
import styles from './StationConfirmation.module.scss';

const initialFadeInDelay = .5;
const confirmationTimeoutSeconds = 10;

const isDebugMode = () => localStorage.get('confirmStation') === 'debug';

export default function StationConfirmation() {
  const [ animatedIn, setAnimatedIn ] = useState(false);
  const [ isComponentInDomFlow, setIsComponentInDomFlow ] = useState(false);
  const [ showChangeStationDialog, setShowChangeStationDialog ] = useState(false);
  const [ showLocalizationConfirmation, setShowLocalizationConfirmation ] = useState(false);
  const { __t } = new LanguageLocalizer( useContext, 'components/global/StationConfirmation' );
  const { station } = useContext(StationLocalizationContext);
  const confirmationTimeoutRef = useRef<null|ReturnType<typeof setTimeout>>(null);
  const toggleButtonRef = useRef({} as HTMLButtonElement);
  const wrapperRef = useRef({} as HTMLDivElement);

  const clearExistingTimeout = () => {
    if (confirmationTimeoutRef.current) {
      clearTimeout(confirmationTimeoutRef.current);
    }
  };

  const resetConfirmationTimeout = useCallback(() => {
    if (isDebugMode()) {
      return;
    }

    clearExistingTimeout();

    confirmationTimeoutRef.current = setTimeout(
      () => {
        if (!showChangeStationDialog) {
          setShowLocalizationConfirmation(false);
        }
      },
      confirmationTimeoutSeconds * 1000,
    );
  }, [ confirmationTimeoutRef, showChangeStationDialog ]);

  const onPointerActivity = useCallback(clearExistingTimeout, []);

  useEffect(() => {
    const wrapperEle = wrapperRef.current;

    const askedThisSession = sessionStorage.get('askedForAutoLocalizationConfirmation') === true;

    if (!isDebugMode() && (askedThisSession || isAutoLocalizationConfirmed())) {
      return;
    }

    const animateIn = () => {
      const delay = initialFadeInDelay * 1000;

      setTimeout(() => {
        setIsComponentInDomFlow(true);
      }, delay - 10);

      setTimeout(() => {
        setShowLocalizationConfirmation(true);
      }, delay);

      setAnimatedIn(true);
    };

    if (!animatedIn) {
      animateIn();
      return;
    }

    const onTransitionEnd = () => {
      if (!showLocalizationConfirmation) {
        sessionStorage.set('askedForAutoLocalizationConfirmation', true);
        setIsComponentInDomFlow(false);
      }
    };

    if (showLocalizationConfirmation) {
      resetConfirmationTimeout();
    }

    if (wrapperEle?.addEventListener) {
      wrapperEle.addEventListener('mouseleave', resetConfirmationTimeout);
      wrapperEle.addEventListener('mouseenter', onPointerActivity);
      wrapperEle.addEventListener('mousemove', onPointerActivity);
      wrapperEle.addEventListener('click', onPointerActivity);
      wrapperEle.addEventListener('transitionend', onTransitionEnd);
    }

    return () => {
      if (wrapperEle?.removeEventListener) {
        wrapperEle.removeEventListener('mouseleave', resetConfirmationTimeout);
        wrapperEle.removeEventListener('mouseenter', onPointerActivity);
        wrapperEle.removeEventListener('mousemove', onPointerActivity);
        wrapperEle.removeEventListener('click', onPointerActivity);
        wrapperEle.removeEventListener('transitionend', onTransitionEnd);
      }
    };
  }, [
    animatedIn,
    onPointerActivity,
    resetConfirmationTimeout,
    showLocalizationConfirmation,
  ]);

  if (!showLocalizationConfirmation && !isComponentInDomFlow) {
    return;
  }

  const onConfirmButton = () => {
    setShowLocalizationConfirmation(false);
    setConfirmedAutoLocalizationCookie(station.callSign);
  };

  const wrapperClasses = `
    ${styles.confirmationOuterWrapper}
    ${showLocalizationConfirmation ? styles.isVisible : ''}
    ${isComponentInDomFlow ? '' : styles.removeFromDomFlow}
    ${showChangeStationDialog ? styles.modalOpen : ''}
  `
    .trim()
    .replace(/\s+/g, ' ');

  return <>
    <div className={wrapperClasses} ref={wrapperRef}>
      <div className={styles.confirmationInnerWrapper}>
        {!showChangeStationDialog && <>
          <h4 className={styles.confirmTitle}>
            {__t('dialogTitle')}
          </h4>

          <button className={styles.selectionButton} onClick={onConfirmButton}>
            {__t('okay')}
          </button>

          <button className={styles.selectionButton} ref={toggleButtonRef}>
            {__t('changeIt')}
          </button>
        </>}

        <StationSettings
          toggleButtonOverrideRef={toggleButtonRef}
          modalOverrideClass={styles.modalOverrideClass}
          suppressBubbleArrowStyle={true}
          onOpen={() => {
            resetConfirmationTimeout();
            setShowChangeStationDialog(true);
          }}
          onClose={() => {
            setShowChangeStationDialog(false);

            if (isAutoLocalizationConfirmed()) {
              clearExistingTimeout();
              setIsComponentInDomFlow(false);
            } else {
              resetConfirmationTimeout();
            }
          }}
          useModalAnimation={false}
        />
      </div>
    </div>
  </>;
}
